import React, { useState, useEffect } from 'react';
import { StyleSheet, Button, View, TextInput, Picker, Text } from 'react-native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createRequest } from '../../graphql/mutations';
import { Colors, Status } from '../../models';
import AWS from 'aws-sdk/global';
import SES from 'aws-sdk/clients/ses';
import { ACCESS_KEY, SECRET_ACCESS_KEY } from '../../config/env';
import { listItems } from '../../graphql/queries';

const RequestBox = () => {
  const [newColor, setNewColor] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newStreetAddress, setNewStreetAddress] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newZipcode, setNewZipcode] = useState('');
  const [newItem, setNewItem] = useState('');
  const [items, setItems] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null); // New state variable
  const accessKey = ACCESS_KEY;
  const secretAccess = SECRET_ACCESS_KEY;

  // Configure SES
  AWS.config.update({
    accessKeyId: accessKey,
    secretAccessKey: secretAccess,
    region: 'us-east-2',
  });

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await API.graphql(graphqlOperation(listItems));
      const items = response.data.listItems.items;
      setItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const onSubmit = async () => {
    if (!newItem) {
      setSubmissionStatus('no_item')
      return; // Abort submission if no item is selected
    }
    if (!newCity || !newStreetAddress || !newState || !newZipcode) {
      setSubmissionStatus('no_destination')
      return; // Abort submission if one of the destination attributes are left out
    }
    if (!newColor) {
      setSubmissionStatus('no_color')
      return; // Abort submission if one of the destination attributes are left out
    }

    const authUser = await Auth.currentAuthenticatedUser();

    const newRequestPackage = {
      color: newColor,
      description: newDescription,
      destination: `${newStreetAddress}\n${newCity}, ${newState} ${newZipcode}`,
      status: Status.SUBMITTED,
      userID: authUser.attributes.sub,
      itemID: newItem,
    };

    // Grabbing list of items (getItem not functioning)
    const itemName = await findItem(newItem);

    let email_item, email_desc, email_dest, email_color;

    if (itemName) {
      email_item = `Hello! \nWe received your order for "${itemName}" and we have started work on this product.`;
    } else {
      console.error(`Item with ID ${newItem} not found.`);
      return; // Abort submission or handle the error appropriately
    }

    if (newDescription) {
      email_desc = `\nDescription: "${newDescription}"`;
    }
    if (newCity && newStreetAddress && newState && newZipcode) {
      email_dest = `Shipping to: \n${newRequestPackage.destination}\n`;
    }

    email_color = `\nColor chosen: ${newColor}\n`;

    var fake_team = "David";
    const real_team = "The PLAzmat Team";

    const email_signature = `\n\nThank you for choosing PLAzmat! If you have any questions about your order, feel free to reply to this email. \n\nSee you soon,\n${fake_team}\nI mean\n${real_team}`;

    const email = email_item + email_desc + email_color + email_dest + email_signature;

    // Create an instance of the SES service
    const ses = new SES();

    // Define the email parameters
    const params = {
      Source: 'plazmat.help@gmail.com',
      Destination: {
        ToAddresses: [authUser.attributes.email], // Replace with the user's email address
      },
      Message: {
        Subject: {
          Data: `Thank you for your submission! ("${itemName}")`,
        },
        Body: {
          Text: {
            Data: email,
          },
        },
      },
    };

    try {
      // Send the email using SES
      await ses.sendEmail(params).promise();
      console.log('Email sent successfully');
      setSubmissionStatus('success'); // Set the submission status to 'success'
    } catch (error) {
      console.log('Error sending email:', error);
      setSubmissionStatus('error'); // Set the submission status to 'error'
    }

    await API.graphql(graphqlOperation(createRequest, { input: newRequestPackage }));

    setNewDescription('');
    setNewStreetAddress('');
    setNewCity('');
    setNewState('');
    setNewZipcode('');
    setNewItem('');
    setNewColor(Colors.RED);
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer}>
        <Picker
          selectedValue={newItem}
          onValueChange={setNewItem}
          style={styles.dropDown}
        >
          <Picker.Item
            label="Select an item"
            value=""
            style={styles.pickerItem} // Apply style to Picker.Item
          />
          {items.map(item => (
            <Picker.Item
              key={item.id}
              label={"#" + item.id.substring(0, 5) + " " + item.name}
              value={item.id}
              style={styles.pickerItem} // Apply style to Picker.Item
            />
          ))}
        </Picker>

        <TextInput
          style={styles.input}
          value={newDescription}
          onChangeText={setNewDescription}
          placeholder="Description"
        />
        <TextInput
          style={[styles.input, styles.streetAddressInput]}
          value={newStreetAddress}
          onChangeText={setNewStreetAddress}
          placeholder="Street Address"
        />
        <TextInput
          style={styles.input}
          value={newCity}
          onChangeText={setNewCity}
          placeholder="City/Village"
        />
        <TextInput
          style={styles.input}
          value={newState}
          onChangeText={setNewState}
          placeholder="State"
        />
        <TextInput
          style={[styles.input, styles.zipcodeInput]}
          value={newZipcode}
          onChangeText={setNewZipcode}
          placeholder="Zipcode"
          keyboardType={'numeric'}
        />
        <Picker
          style={styles.dropDown}
          selectedValue={newColor}
          onValueChange={setNewColor}
        >
          <Picker.Item label="Pick a color" value={null}/>
          <Picker.Item label="Red" value={Colors.RED} />
          <Picker.Item label="Glow" value={Colors.GLOW} />
          <Picker.Item label="Gold" value={Colors.GOLD} />
          <Picker.Item label="Wood" value={Colors.WOOD} />
        </Picker>
      </View>
      {submissionStatus === 'success' && (
        <Text style={styles.successMessage}>Submission successful!</Text>
      )}
      {submissionStatus === 'no_item' && (
        <Text style={styles.errorMessage}>Please select an item.</Text>
      )}
      {submissionStatus === 'no_destination' && (
        <Text style={styles.errorMessage}>Please include all destination details.</Text>
      )}
      {submissionStatus === 'no_color' && (
        <Text style={styles.errorMessage}>Please select a color.</Text>
      )}
      {submissionStatus === 'error' && (
        <Text style={styles.errorMessage}>Submission failed. Please try again.</Text>
      )}
      <View style={styles.buttonContainer}>
        <Button onPress={onSubmit} title="Submit" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  inputContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  pickerItem: {
    color: 'black', // Set font color to black
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 10,
    marginBottom: 10,
    color: 'white',
    backgroundColor: '#5A5A4A'
  },
  dropDown: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 5,
    marginBottom: 5,
    color: 'black',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  successMessage: {
    color: 'green',
    marginBottom: 10,
    textAlign: 'center',
  },
  errorMessage: {
    color: 'red',
    marginBottom: 10,
    textAlign: 'center',
  },
});

async function findItem(id) {
  const response = await API.graphql(graphqlOperation(listItems));
  const items = response.data.listItems.items;
  const filteredObject = items.find(item => item.id === id);
  if (filteredObject) {
    console.log("FIND ITEM: " + filteredObject.name);
    return filteredObject.name; // Return the item name
  } else {
    console.log("No object found with the specified ID.");
    return null;
  }
}

export default RequestBox;