import React from "react";
import { StyleSheet, Text, View, Button, Linking } from "react-native";
import { Colors } from '../../models';
import { API, graphqlOperation } from 'aws-amplify';
import { updateRequest } from '../../graphql/mutations';

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const RequestItem = ({ request }) => {
  let colBackStyle = null;

  switch (request.color) {
    case Colors.RED:
      colBackStyle = { backgroundColor: "red" };
      break;
    case Colors.GOLD:
      colBackStyle = { backgroundColor: "gold", colorText: "black" };
      break;
    case Colors.GLOW:
      colBackStyle = { backgroundColor: "yellow", colorText: "black" };
      break;
    case Colors.WOOD:
      colBackStyle = { backgroundColor: "brown" };
      break;
    default:
      break;
  }

  const handlePayment = async () => {
  const PRICE_ID = `price_1NS4t4Gy0OcguP5aQdLrjSZ8`;
  try {
    const response = await fetch('https://plazmat.com/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: PRICE_ID,
      }),
    });

    const sessionData = await response.json();
    console.log(sessionData);

    // Update request status
    await updateRequestStatus(request.id, 'IN_PROGRESS');

    // Redirect the user to the Stripe checkout URL
    Linking.openURL(sessionData.url);
  } catch (error) {
    // Handle error
    console.log(error);
  }
};


  const updateRequestStatus = async (requestId, status) => {
    try {
      await API.graphql(graphqlOperation(updateRequest, { input: { id: requestId, status } }));
      // Request status updated successfully
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  return (
    <View style={[styles.itemContainer, colBackStyle]}>
      <View style={styles.column}>
        <Text>{request.description}</Text>
      </View>
      <View style={styles.column}>
        <Text>{request.destination}</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.colorText}>{request.color}</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.subTitle}>{request.status}</Text>
        {request.status === 'INSTRUCTIONS_SENT' && (
          <Button title="Pay Now" onPress={handlePayment} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    height: 50,
    marginHorizontal: 0, // Adjust the horizontal margin as needed
    borderWidth: 5
  },
  column: {
    flex: 1,
    justifyContent: "center",
  },
  subTitle: {
    color: "black",
    textAlign: "center",
    fontWeight: "bold"
  },
  colorText: {
    color: "black",
    textAlign: "center",
  },
});

export default RequestItem;
