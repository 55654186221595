// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Status = {
  "SUBMITTED": "SUBMITTED",
  "INSTRUCTIONS_SENT": "INSTRUCTIONS_SENT",
  "IN_PROGRESS": "IN_PROGRESS",
  "EN_ROUTE": "EN_ROUTE",
  "DELIVERED": "DELIVERED"
};

const Colors = {
  "RED": "RED",
  "WOOD": "WOOD",
  "GOLD": "GOLD",
  "GLOW": "GLOW"
};

const { Item, Request, User, Candidate } = initSchema(schema);

export {
  Item,
  Request,
  User,
  Candidate,
  Status,
  Colors
};