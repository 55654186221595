import React from "react";
import { StyleSheet, Text, View, Image, Dimensions } from "react-native";

const getDescriptionHeight = (description) => {
  const descriptionLines = description.split("\n").length;
  const baseHeight = 20; // Adjust the base height as needed
  const additionalHeightPerLine = 15; // Adjust the additional height per line as needed
  return baseHeight + descriptionLines * additionalHeightPerLine;
};

const CatalogItem = ({ catalogItem }) => {
  const windowHeight = Dimensions.get("window").height;
  const itemHeight = windowHeight / 10; // Adjust the divisor as needed
  const imageHeight = itemHeight * 0.9; // Adjust the multiplier to control the image size

  const displayResizedImage = (imageSource) => {
    const imageContainerStyle = {
      height: imageHeight,
      aspectRatio: 1,
    };

    const imageStyle = {
      flex: 1,
      resizeMode: "contain",
    };

    return (
      <View style={imageContainerStyle}>
        <Image source={imageSource} style={imageStyle} />
      </View>
    );
  };

  const itemContainerStyle = [
    styles.itemContainer,
    { height: itemHeight + getDescriptionHeight(catalogItem.description) },
  ];

  const showPhoto = Dimensions.get("window").width - itemHeight > itemHeight;

  return (
    <View style={itemContainerStyle}>
      <View style={styles.idContainer}>
        <View style={styles.idColumn}>
          <Text>
            <b>{"#" + catalogItem.id.substring(0, 5)}</b>
          </Text>
        </View>
        <View style={styles.emptyColumn}></View> {/* Empty View for alignment */}
      </View>
      <View style={styles.nameContainer}>
        <Text style={styles.itemText}>{catalogItem.name}</Text>
      </View>
      <View style={styles.descriptionContainer}>
        <Text style={styles.descriptionText} numberOfLines={4}>
          {catalogItem.description}
        </Text>
      </View>
      <View style={styles.emptyColumn}></View> {/* Render your View component for the "View" column here */}
      {showPhoto && (
        <View style={styles.photoColumn}>
          {displayResizedImage(require("../../img/" + catalogItem.frontImage))}
          {displayResizedImage(require("../../img/" + catalogItem.backImage))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    marginHorizontal: 0,
    borderWidth: 5,
    backgroundColor: "#F5F5F5",
  },
  idContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  emptyColumn: {
    width: 50,
    alignItems: "center",
  },
  idColumn: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  column: {
    flex: 1,
    justifyContent: "center",
  },
  nameContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  photoColumn: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start", // Move the image to the left
    flex: 1, // Occupy remaining space within the container
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10, // Add padding to ensure text doesn't spill outside
  },
  itemText: {
    fontSize: 14,
    marginBottom: 5,
  },
  descriptionText: {
    fontSize: 14,
    textAlign: "center", // Center the text within the container
  },
});

export default CatalogItem;
