import React from "react";
import { StyleSheet, View, Text } from "react-native";

const RequestHeading = () => {
  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <Text style={styles.headingText}>Description</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.headingText}>Destination</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.headingText}>Color</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.headingText}>Status</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    height: 60,
    backgroundColor: "#f2f2f2",
    marginHorizontal: 10, // Adjust the horizontal margin as needed
  },
  column: {
    flex: 1,
    justifyContent: "center",
  },
  headingText: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 16,
  },
});

export default RequestHeading;
