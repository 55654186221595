import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const CaptionPage = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>Welcome to <Text style={styles.title}>Plazmat</Text>!</Text>
      <Text style={styles.text}>
        Plazmat is a 3D-printing distributor that creates and ships orders and requests from users. These orders can be taken from our menu or custom created, providing solutions to unique issues, like a holder to keep your dryer door open, or assisting in advertising, engraving a QR code onto a case of 25 guitar picks.
        To get started, put in a request below including an item picked from our catalog, or a custom design, with a description (which can later be followed up with a more specified description or a file in an email), color choice, and delivery instructions. If you'd like your custom design to be added to our catalog, please submit a request to add it in your response email.
        After putting in a request, you will receive a confirmation email with the details of your order and what to be on the lookout for. This will be your main form of communication between us, so keep this email chain going.
        If you would like to give a more specified description of your print, please include that in response to your Request Confirmation. We could also just chat if you want. I’m a chatter. Or, the person responding to the emails is a chatter. But you’re gonna have to lead the conversation.
      </Text>
      <Text style={styles.text}>
        If you've got any questions, feel free to email us at <Text style={[styles.email, styles.boldText]}>plazmat.help@gmail.com</Text>.
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 30,
    fontFamily: 'Tahoma',
  },
  text: {
    color: 'white',
    marginBottom: 15,
    fontFamily: 'Tahoma',
    textAlign: 'left',
    textShadowColor: 'gray',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
  },
  heading: {
    color: 'white',
    marginBottom: 15,
    fontSize: 46,
    fontFamily: 'Tahoma',
    textShadowColor: 'gray',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
  },
  title: {
    color: '#FF0000',
    marginBottom: 15,
    fontSize: 46,
    fontFamily: 'Tahoma',
    textShadowColor: '#400000',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
  },
  boldText: {
    fontWeight: 'bold',
  },
  email: {
    fontWeight: 'bold',
  },
});

export default CaptionPage;
