import React, { useEffect } from "react";
import logo from './PLAster2.png';
import './App.css';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { getUser } from './graphql/queries';
import { createUser as createUserMutation } from './graphql/mutations';
import RequestsPage from './pages/RequestsPage';
import SettingsPage from './pages/SettingsPage';
import CatalogPage from './pages/CatalogPage';
import CaptionPage from './pages/CaptionPage';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
  useEffect(() => {
    const syncUser = async () => {
      try {
        // Get the authenticated user
        const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        // Query the user data from the database
        const { data } = await API.graphql(graphqlOperation(getUser, { id: authUser.attributes.sub }));

        // If no user exists, create a new user
        if (!data.getUser) {
          const newUser = {
            email: authUser.attributes.email,
            id: authUser.attributes.sub,
            name: authUser.attributes.name,
            image: authUser.attributes.image,
            requests: 0
          };

          // Create the user in the database
          await API.graphql(graphqlOperation(createUserMutation, { input: newUser }));
        }
      } catch (error) {
        console.error('Error syncing user:', error);
      }
    };

    syncUser();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="floating-settings">
          <SettingsPage />
        </div>
        <img src={logo} className="App-logo" alt="logo" />
        <CaptionPage/>
        <div className="table-container">
          <CatalogPage />
          <RequestsPage user={Auth} />
        </div>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
