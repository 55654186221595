import React from "react";
import { StyleSheet, View, Text } from "react-native";

const CatalogHeading = () => {
  return (
    <View style={styles.container}>
      <View style={styles.emptyColumn}></View> {/* Empty View for alignment */}
      <View style={styles.column}>
        <Text style={styles.headingText}>ID</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.headingText}>Name</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.headingText}>Description</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.headingText}>View</Text>
      </View>
      <View style={styles.emptyColumn}></View> {/* Empty View for alignment */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 0, // Adjust the value as needed
    height: 60,
    backgroundColor: "#f2f2f2",
  },
  column: {
    flex: 1,
    justifyContent: "center",
  },
  emptyColumn: {
    width: 50,
    alignItems: "center",
  },
  headingText: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 16,
  },
  
});

export default CatalogHeading;