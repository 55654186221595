import React, { useEffect, useState } from 'react';
import { FlatList, View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listItems } from '../graphql/queries';
import CatalogItem from '../components/CatalogItem';
import CatalogHeading from '../components/CatalogHeading';

const CatalogPage = () => {
  const [items, setItems] = useState([]);
  const [isCatalogHidden, setIsCatalogHidden] = useState(true);

  const toggleHidden = () => {
    setIsCatalogHidden(!isCatalogHidden);
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.signInUserSession.accessToken.jwtToken;

        const result = await API.graphql(graphqlOperation(listItems), {
          headers: {
            Authorization: token
          }
        });

        const itemsData = result.data.listItems.items;
        setItems(itemsData);
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchItems();
  }, []);

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.button} onPress={toggleHidden}>
        <Text style={styles.buttonText}>Catalog Listing</Text>
      </TouchableOpacity>
      {isCatalogHidden ? null : (
        <FlatList
          data={items}
          renderItem={({ item }) => (
            <View style={styles.itemContainer}>
              <CatalogItem catalogItem={item} />
            </View>
          )}
          keyExtractor={(item) => item.id}
          ListHeaderComponent={<CatalogHeading />}
          ListEmptyComponent={<Text style={{ color: 'white' }}>Catalog Empty.</Text>}
          contentContainerStyle={styles.flatListContent}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    backgroundColor: '#007aff',
    borderRadius: 10,
    paddingHorizontal: 50,
    padding: 10,
    margin: 10,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  itemContainer: {
    marginBottom: 10,
    paddingHorizontal: 10, // Add padding to ensure proper spacing between items
  },
  flatListContent: {
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 20,
  },
});

export default CatalogPage;
