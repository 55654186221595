import React, { useEffect, useState } from 'react';
import { FlatList, View, TouchableOpacity, Text, StyleSheet, Image, useWindowDimensions } from 'react-native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listRequests } from '../graphql/queries';
import { onCreateRequest } from '../graphql/subscriptions';
import RequestBox from '../components/RequestBox';
import RequestItem from '../components/RequestList';
import RequestHeading from '../components/RequestHeading';
import magnifyGlass from '../img/magnifyGlass.png';

const RequestsPage = (user) => {
  const [requests, setRequests] = useState([]);
  const [isRequestHidden, setIsRequestHidden] = useState(true);
  const windowWidth = useWindowDimensions().width;

  const toggleHidden = () => {
    setIsRequestHidden(!isRequestHidden);
  };

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.signInUserSession.accessToken.jwtToken;

        const result = await API.graphql({
          query: listRequests,
          headers: {
            Authorization: token
          }
        });
        if (result?.data?.listRequests?.items) {
          // Filter out deleted items before setting state
          const filteredItems = result.data.listRequests.items.filter(
            (item) => !item._deleted
          );
          setRequests(filteredItems);
        }
      } catch (error) {
        console.log('Error:', error.response);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onCreateRequest)).subscribe({
      next: (eventData) => {
        const newRequest = eventData.value.data.onCreateRequest;
        setRequests((currentRequests) => [...currentRequests, newRequest]);
      },
      error: (error) => console.log(error),
    });

    return () => subscription.unsubscribe();
  }, []);

  const renderHeader = () => {
    if (requests.filter(request => request.userID === user.user.user.attributes.sub).length === 0) {
      return null; // Hide the header when there are no requests
    }

    return <RequestHeading />;
  };

  const renderEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <Image source={magnifyGlass} style={styles.image} />
        <Text style={styles.emptyText}>No requests found.</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.button} onPress={toggleHidden}>
        <Text style={styles.buttonText}>New Request</Text>
      </TouchableOpacity>
      {isRequestHidden ? null : <RequestBox />}
      <FlatList
        data={requests.filter(request => request.userID === user.user.user.attributes.sub)}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <RequestItem request={item} />
          </View>
        )}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={renderHeader()}
        ListEmptyComponent={renderEmptyComponent}
        contentContainerStyle={[styles.flatListContent, { width: windowWidth / 1.2 }]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    backgroundColor: '#007aff',
    borderRadius: 10,
    paddingHorizontal: 50,
    padding: 10,
    margin: 10,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  itemContainer: {
    marginBottom: 10, // Added margin-bottom for spacing between items
  },
  flatListContent: {
    paddingHorizontal: 10, // Added horizontal padding for spacing
    paddingTop: 10, // Added top padding for spacing
    paddingBottom: 20, // Added bottom padding for spacing
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  emptyText: {
    color: 'white',
  },
});

export default RequestsPage;