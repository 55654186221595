import React from "react";
import { View, Button, Text } from "react-native";
import { Auth } from "aws-amplify";

const SettingsPage = () => {
  // Get the authenticated user's email
  const userEmail = Auth.user.attributes.email;

  return (
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "dark gray" }}>
      <Text style={{ color: "white", fontWeight: "bold" }}>{userEmail}</Text>
      <Button onPress={() => Auth.signOut()} title="Sign out" />
    </View>
  );
};

export default SettingsPage;
